import React, {useEffect, useState} from "react";
import MeasureList_get from "../../http/GET/MeasureList_get";
import Download_pcg_get from "../../http/GET/Download_pcg_get";
import {Line} from "react-chartjs-2";
import Chart_Data from "./chart/chart";
import Ppg_Pcg_Data from "./chart/ppg_pcg_data";
import Download_ppg_get from "../../http/GET/Download_ppg_get";
import {Dropdown} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserList_pagenation from "../UserList/UserList_pagenation";
import {useParams} from "react-router-dom";
import {useOutletContext} from "react-router";


async function streamToByteArray(responseStream) {
    const reader = responseStream.getReader();
    const chunks = [];
    let done, value;

    while ({done, value} = await reader.read(), !done) {
        chunks.push(value);
    }

    // 합쳐진 데이터를 하나의 Uint8Array로 변환
    let length = chunks.reduce((sum, chunk) => sum + chunk.length, 0);
    let byteArray = new Uint8Array(length);
    let offset = 0;

    for (let chunk of chunks) {
        byteArray.set(chunk, offset);
        offset += chunk.length;
    }

    return byteArray;
}

function byteArrayToFloatArray(byteArray, sampleWidth) {
    if (sampleWidth !== 2 && sampleWidth !== 4) {
        throw new Error('Not supported Sample Width.  Your SampleWidth is ' + sampleWidth);
    }

    const numSamples = byteArray.length / sampleWidth;
    const floatArray = new Float32Array(numSamples);

    for (let i = 0; i < numSamples; i++) {
        // 바이트 오프셋
        const offset = i * sampleWidth;

        let sample;
        if (sampleWidth === 2) {    // 2 이면 16비트 ( 시네스퍼 기본 비트 )
            // 16비트 PCM 일기
            sample = byteArray[offset] | (byteArray[offset + 1] << 8);
            // 16비트 PCM -32768 ~ 32767 범위
            if (sample & 0x8000) {
                sample -= 0x10000;
            }
            floatArray[i] = sample / 32768.0;
        } else if (sampleWidth === 4) {     // 4 이면 32비트
            // 32비트 PCM 데이터 읽기 (대부분 24비트 PCM 데이터는 4바이트로 표현됨)
            sample = byteArray[offset] | (byteArray[offset + 1] << 8) | (byteArray[offset + 2] << 16) | (byteArray[offset + 3] << 24);
            // 32비트 PCM은 -2147483648 ~ 2147483647 범위
            floatArray[i] = sample / 2147483648.0;
        }
    }

    return floatArray;
}


const C_Measure_page = () => {

    const {id} = useParams();
    const setModalOpen =useOutletContext().setModalOpen;

    const [now_page, setNow_page] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [hearts_data, setHearts_data] = useState([]);
    const [hearts_graph, setHearts_graph] = useState({});
    const [chart_data, setChart_data] = useState({});

    const [onoff,setonoff] = useState(false);


    const handleClick_pcg = async (event, heart_data) => {
        event.preventDefault(); // 기본 a 태그 동작을 막음
        const result = await Download_pcg_get(heart_data.measureId);
        //console.log(result);

        if (result === 1 || result === 500) {
            alert("데이터 없음");
        } else if (result === 0) {
            setModalOpen(true);
        } else {
            window.location.href = `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/pcg/${heart_data.measureId}`;
        }
    };

    const handleClick_ppg = async (event, heart_data) => {
        event.preventDefault(); // 기본 a 태그 동작을 막음

        const result = await Download_ppg_get(heart_data.measureId);

        if (result === 1) {
            alert("데이터 없음");
        } else if (result === 0) {
            setModalOpen(true);
        } else {
            window.location.href = `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/ppg/${heart_data.measureId}`;
        }
    };


    useEffect(() => {
        setonoff(false);

        setChart_data(Chart_Data(0, 0));

        const fetchData = async () => {
            let heartdata = await MeasureList_get(id,now_page);
            let new_hearts_graph  = {};

            if (heartdata) {
                setHearts_data(heartdata.content); // data.content를 상태로 저장

                heartdata.content.map( async (heart,index) => {

                    const result = await Download_pcg_get(heart.measureId);

                    const original_ppg = await Download_ppg_get(heart.measureId);

                    if(result===500 )
                    {
                        return;
                    }
                    else if(result != 0 || result != 1){

                        const byte_data = await streamToByteArray(result);
                        const sampleWidth = 2; // 16비트 고정 (하드코딩)
                        const floatArray = byteArrayToFloatArray(byte_data, sampleWidth);


                        if(floatArray.length < 160000)
                        {
                            console.log('PCG is too Short !!!');
                            console.log(heart.measureId);
                            return;
                        }
                        else if(original_ppg.length < 1000 )
                        {
                            console.log('PPG is too short !!!');
                            console.log(heart.measureId);
                            return
                        }




                        const test_data = Chart_Data(floatArray, original_ppg,heart.measureId);
                        new_hearts_graph[`${heart.measureId}`] = test_data;
                        setHearts_graph(new_hearts_graph);
                    }

                    /*
                    if((index+1)===heartdata.content.length)
                    {
                        setonoff(true);
                    }
                    */
                })
                setTotalPages(heartdata.page.totalPages); // data.totalPages를 상태로 저장
            } else {
                console.log("데이터가 없습니다.");
            }

            return new_hearts_graph;
        };

        fetchData();

        const timer = setTimeout(() => {
            console.log(hearts_graph);
            setonoff(true);
        }, 1000); // 10000ms = 10초

        // 컴포넌트가 언마운트될 때 타이머를 정리합니다.
        return () => clearTimeout(timer);


    }, [now_page]);

    useEffect(()=>{

    },[totalPages]);


    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_body"}>
                {hearts_data.map((heart_data, index) => (
                    <div className={"graph_tool"} key={heart_data.measureId}>
                        <div className={"graph_img_frame"}>
                            <div>
                                <div>
                                    measureId : {heart_data.measureId}
                                </div>
                            </div>
                            <div className={"graph"}>
                                {
                                    onoff ?
                                        hearts_graph[heart_data.measureId] != undefined ?
                                            <Line data={hearts_graph[heart_data.measureId].data} options={hearts_graph[heart_data.measureId].options}/>
                                            :
                                            <Line data={chart_data.data} options={chart_data.options}/>
                                        :
                                        <Line data={chart_data.data} options={chart_data.options}/>

                                }

                            </div>
                        </div>
                        <div className={"graph_data_frame"}>
                            <table>
                                <thead>
                                <tr>
                                    <th>
                                        <p className={"test_font"}>평균</p>
                                        <p className={"test_font_a"}>{heart_data.mavg}</p>
                                    </th>
                                    <th><p className={"test_font"}>최대</p>
                                        <p className={"test_font_a"}>{heart_data.mmax}</p>
                                    </th>
                                    <th>
                                        <p className={"test_font"}>최소</p>
                                        <p className={"test_font_a"}>{heart_data.mmin}</p>
                                    </th>
                                </tr>
                                </thead>
                            </table>
                            <div id={"aaa"}>
                                {heart_data.date}
                            </div>
                            <div className={"cardio-graph_download_frame"}>

                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Dropdown Button
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={(event) => {
                                            handleClick_pcg(event, heart_data);

                                            /*
                                            const data = await Download_pcg_get(heart_data.measureId);
                                            const byte_data = await streamToByteArray(data);
                                            const sampleWidth = 2; // 16비트 고정 (하드코딩)
                                            const floatArray = byteArrayToFloatArray(byte_data, sampleWidth);
                                            let data1 = await Download_ppg_get(heart_data.measureId);
                                            const test_data = Chart_Data(floatArray, data1);
                                            setChart_data(test_data);
                                             */

                                        }}>pcg DownLoad</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={(event) => {
                                                handleClick_ppg(event, heart_data);
                                            }}
                                        >ppg DownLoad</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                ))}
                <UserList_pagenation setNow_page={setNow_page} now_page={now_page} totalPages={totalPages}/>
            </div>

        </div>
    );
};


/*
<ul className="menu align-center expanded text-center SMN_effect-103">
                                    <li><a
                                        href={`https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/pcg/${heart_data.measureId}`}
                                        onClick={async (event) => {
                                            handleClick_pcg(event, heart_data);


                                            const data = await Download_pcg_get(heart_data.measureId);
                                            const byte_data = await streamToByteArray(data);
                                            const sampleWidth = 2; // 16비트 고정 (하드코딩)
                                            const floatArray = byteArrayToFloatArray(byte_data, sampleWidth);
                                            let data1 = await Download_ppg_get(heart_data.measureId);
                                            const test_data = Chart_Data(floatArray, data1);
                                            setChart_data(test_data);


}}>
<span><p>pcg</p></span></a></li>
<li><a
href={`https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/ppg/${heart_data.measureId}`}
onClick={(event) => {
    handleClick_ppg(event, heart_data);
}}
>
<span>
<p>ppg</p>
</span>
</a></li>
</ul>
 */
export default C_Measure_page;
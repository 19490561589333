import {useEffect, useRef, useState} from "react";
import {FaChevronRight, FaChevronLeft, FaSearch, FaRegPlusSquare} from "react-icons/fa";
import UserList_pagenation from "./UserList_pagenation";
import UserList_get from "../../http/GET/UserList_get";
import Role_check_get from "../../http/GET/Role_check_get";
import Manger_check_get from "../../http/GET/Manger_check_get";
import {useOutletContext} from "react-router";
import F_ExpireSession_get from "../../http/GET/F_ExpireSession_get";
import F_Disable_post from "../../http/POST/F_Disable_post";
import F_Enable_post from "../../http/POST/F_Enable_post";
import {useLocation, useNavigate} from "react-router-dom";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const F_UserList_page = () => {

    const setModalOpen = useOutletContext().setModalOpen;
    const upc = useOutletContext().UserData_page_change;
    const mpc = useOutletContext().Measure_page_change;

    const page_number = parseInt(useQuery().get("page"));
    const [count,setCount] = useState(0);
    const [now_page, setNow_page] = useState(page_number-1);
    const [totalPages, setTotalPages] = useState(1);


    const [users, setUsers] = useState([]);
    const [role, setrole] = useState(false);
    const [search_name, setSearch_name] = useState("");
    const navigate = useNavigate();

    const change_page = (data)=>{
        setNow_page(data);
        navigate(`/synesper-lite-family/userlist?page=${data+1}`);
    }

    const fetchData = async () => {
        setUsers([]);

        const role_data = await Role_check_get();
        if (role_data === 401) {
            setModalOpen(true);
        } else if (role_data === "error") {
            console.log("error");
        } else {

            if (role_data.role === "MANAGER") {
                setrole(false);
            } else {
                setrole(true);
            }


            let data = await UserList_get(page_number-1, search_name);
            if (data) {
                setUsers(data.content); // data.content를 상태로 저장
                setTotalPages(data.page.totalPages); // data.totalPages를 상태로 저장
            } else {
                console.log("데이터가 없습니다.");
            }
        }
    };

    useEffect(() => {
        fetchData();
        console.log(count);
    }, [now_page,count,page_number]);



    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_body"}>
                <div className={"검색창"}>
                    <input type="search" placeholder="Search Data..." value={search_name}
                           onChange={(e) => setSearch_name(e.target.value)}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   event.preventDefault();
                                   if (now_page === 0) {
                                       fetchData();
                                   } else {
                                       setNow_page(0);
                                   }
                               }
                           }}
                    />
                    <a onClick={() => {
                        if (now_page === 0) {
                            fetchData();
                        } else {
                            setNow_page(0);
                        }
                    }}><FaSearch size={"45"} style={{paddingLeft: "20px"}}/></a>
                </div>
                <div className={"list_page_base"}>
                    <table className={"list_table"}>
                        <thead className={"list_thead"}>
                        <tr className={"list_tr"}>
                            <th className={"list_th"}> ID</th>
                            <th className={"list_th"}> 이름</th>
                            <th className={"list_th"}> email</th>
                            <th className={"list_th"}> 유저 출산 예정일</th>
                            <th className={"list_th"}> 기기 번호</th>
                            <th className={"list_th"}> 활성화</th>
                            <th className={"list_th"}> Logout</th>
                            {role ? <th className={"list_th"}> 심박</th> : null}
                        </tr>
                        </thead>
                        <tbody className={"list_tbody"}>

                        {users.map(user => (
                            <tr className={"list_tr"} key={user.accountId}>
                                <td className={"list_td"} onClick={async () => {

                                    let chack = await Manger_check_get();
                                    if (chack === true) {
                                        upc(user);
                                    } else if (chack === false) {
                                        setModalOpen(true);
                                    } else {
                                        console.log("error 발생");
                                    }

                                }}>{user.userId}</td>
                                <td className={"list_td"}>{user.userName}</td>
                                <td className={"list_td"}>{user.userEmail}</td>
                                <td className={"list_td"}>{user.delivery}</td>
                                <td className={"list_td"}>{user.deviceSerialNumber}</td>
                                {
                                    user.enable ?
                                        <td style={{color: "green"}} onClick={()=>{
                                            F_Disable_post(user.accountId,setCount,count);
                                        }}><h3>O</h3></td> :
                                        <td style={{color: "red"}} onClick={()=>{
                                            F_Enable_post(user.accountId,setCount,count);
                                        }}><h3>X</h3></td>
                                }
                                <td className={"list_td"}>
                                    <button onClick={()=>{
                                        F_ExpireSession_get(user.accountId);
                                    }}>
                                        Logout
                                    </button>
                                </td>
                                {/* 심박 정보가 없으므로 N/A로 표시 */}
                                {
                                    role ?
                                        <td className={"list_td"}>
                                            <a onClick={async () => {

                                                const chack = await Manger_check_get();

                                                if (chack === true) {
                                                    mpc(user);
                                                } else if (chack === false) {
                                                    setModalOpen(true);
                                                } else {
                                                    console.log(chack);
                                                    console.log("hello ");
                                                    console.log("error 발생");
                                                }

                                            }}>자세히 보기
                                            </a>
                                        </td>
                                        : null
                                }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <UserList_pagenation change_page={change_page} setNow_page={setNow_page} now_page={page_number-1} totalPages={totalPages}/>

            </div>
        </div>
    );
};

export default F_UserList_page;




/*
    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber.length === 11) {
            return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
        return phoneNumber;
    };



    useEffect(() => {
        // 예제 데이터를 사용하여 상태 초기화
        const data = [
                {
                    "accountId": 1,
                    "userId": "MasterAdmin",
                    "userEmail": "MasterAdmin@clairaudience.co.kr",
                    "userName": "MasterAdmin",
                    "delivery": "MasterAdmin",
                    "deviceSerialNumber": "NOT REGISTERED",
                    "date": "2023-11-11T03:46:19.160+00:00"
                },

            ]
        ;
        setUsers(data);
    }, []);

*/